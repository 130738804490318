<template>
  <dashboard-layout>
    <div class="my-5 py-5 overflow-hidden">
      <div class="row justify-content-center">
        <div class="col-10">
          <div class="mt-4">
            <h1>Playlist Creation Center</h1>
          </div>
          <button
            type="button"
            class="btn btn-primary text-white"
            @click="openPlaylistModal"
          >
            Create Playlist
          </button>
          <playlist-modal
            :playlist="editedPlaylist"
            :method="playlistMethod"
            @reset-default="closePlaylistModal"
            ref="playlistModal"
          ></playlist-modal>
          <playlists class="mt-8"></playlists>
        </div>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import { Modal } from "bootstrap"
import DashboardLayout from "@/layouts/Dashboard.vue"
import PlaylistModal from "@/components/dashboard/playlist/PlaylistModal.vue"
import Playlists from "@/components/dashboard/playlist/Playlists.vue"
import { loadScript } from "@/utils/misc"

export default {
  name: "AdminPlaylist",
  components: { DashboardLayout, PlaylistModal, Playlists },
  data() {
    const editedPlaylist = {
      name: "",
      description: "",
      image_path: ""
    }
    return {
      playlistMethod: "create",
      editedPlaylist,
      defaultPlaylist: Object.freeze(Object.assign({}, editedPlaylist))
    }
  },
  mounted() {
    loadScript()
  },
  methods: {
    closePlaylistModal() {
      this.editedPlaylist = Object.assign({}, this.defaultPlaylist)
    },
    openPlaylistModal() {
      const modal = new Modal(this.$refs.playlistModal.$refs.playlistModal)
      modal.show()
    }
  }
}
</script>
