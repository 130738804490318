<template>
  <div class="card mb-3 border-0 px-4" style="max-width: 840px">
    <div class="g-0 card-body">
      <track-delete-modal
        :track_id="track.id"
        :playlist_id="track.playlist_id"
      ></track-delete-modal>
      <track-modal
        :track="track"
        :method="trackMethod"
        ref="trackModal"
      ></track-modal>
      <div>
        <video
          :id="'track-player' + track.id"
          v-if="track.song"
          controls="true"
          controlslist="nodownload"
          fallbackContent="Your browser does not support HTML5 video tags."
          class="cld-video-player cld-video-player-skin-dark cld-fluid rounded-start border"
        ></video>
      </div>

      <div class="text-start">
        <h5 class="card-title">
          <div class="row">
            <div class="col-8 text-start">{{ track.title }}</div>
            <div class="col-4 text-end">
              <div class="dropdown">
                <button
                  class="option-btn btn px-1 pb-1"
                  type="button"
                  id="trackActions"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="bi bi-three-dots-vertical"></i>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <button class="dropdown-item" @click="openTrackModal">
                      Edit Track
                    </button>
                  </li>
                  <li>
                    <button
                      class="dropdown-item"
                      data-bs-toggle="modal"
                      :data-bs-target="'#trackDeleteModal' + track.id"
                    >
                      Move to trash
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </h5>
        <div class="card-text">
          <div class="text-start">Song by {{ track.artist_names }}</div>
          <p>
            <small class="text-muted">
              Uploaded on
              {{ moment(track.created_at).format("MMMM Do YYYY, h:mm:ss a") }}
            </small>
          </p>
        </div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap"
import TrackDeleteModal from "@/components/dashboard/playlist/TrackDeleteModal.vue"
import moment from "moment"
import TrackModal from "@/components/dashboard/playlist/TrackModal.vue"
import { getCloudinaryPId } from "@/utils/misc"

export default {
  name: "PlaylistTrack",
  props: { track: Object },
  components: { TrackDeleteModal, TrackModal },
  data() {
    return {
      trackMethod: "edit"
    }
  },
  mounted() {
    this.initPlayer()
  },
  methods: {
    openTrackModal() {
      const modal = new Modal(this.$refs.trackModal.$refs.trackModal)
      modal.show()
    },
    moment: moment,
    getCloudinaryPId: getCloudinaryPId,
    initPlayer() {
      const logUrl =
        "https://res.cloudinary.com/dkj13ry1j/image/upload/v1688931206/Propelond_Logo2_qwybsb.png"
      this.videoPlayer = window.cloudinary.videoPlayer(
        `track-player${this.track.id}`,
        {
          cloud_name: process.env.VUE_APP_CLOUDINARY_CLOUD_NAME,
          secure: true,
          logoImageUrl: logUrl,
          logoOnclickUrl: "https://www.propelond.com/",
          playbackRates: [0.25, 0.5, 0.75, 1, 1.5, 1.25, 1.75, 2],
          colors: { accent: "#FF0DB8" },
          seekThumbnails: true
        }
      )
      this.videoPlayer.source(getCloudinaryPId(this.track.song))
      this.videoPlayer.transformation({ quality: "auto" })
    }
  }
}
</script>
