<template>
  <div class="accordion-item">
    <h2 class="accordion-header" :id="'panelHeading' + playlist.id">
      <button
        class="accordion-button"
        :class="index != 0 && 'collapsed'"
        type="button"
        data-bs-toggle="collapse"
        :data-bs-target="'#panelAccordion' + playlist.id"
        :aria-expanded="index == 0"
        :aria-controls="'panelAccordion' + playlist.id"
      >
        <div>{{ playlist.name }}</div>
        <small class="text-muted ms-10">
          {{ moment(playlist.created_at).format("MMMM Do YYYY, h:mm:ss a") }}
        </small>
      </button>
    </h2>
    <div
      :id="'panelAccordion' + playlist.id"
      class="accordion-collapse collapse"
      :class="index == 0 && 'show'"
      :aria-labelledby="'panelHeading' + playlist.id"
    >
      <div class="accordion-body">
        <playlist-delete-modal :playlist_id="playlist.id">
        </playlist-delete-modal>
        <track-modal
          :track="editedTrack"
          :method="trackMethod"
          @reset-default="closeTrackModal"
          ref="trackModal"
        ></track-modal>
        <playlist-modal
          :playlist="playlist"
          :method="playlistMethod"
          ref="playlistModal"
        ></playlist-modal>
        <div class="card mb-4 my-2 text-dark bg-light">
          <div class="card-body">
            <div class="row">
              <div class="col-md-8 text-start">
                <div class="row">
                  <div class="col-4">
                    <AdvancedImage
                      :cldImg="playlistImg"
                      :plugins="imgPlugins"
                      style="width: 50px"
                      class="rounded-3 mt-2 text-center"
                      v-if="playlist.image_path"
                    />
                  </div>
                  <div class="col-8">
                    {{ playlist.description }}
                  </div>
                </div>
              </div>
              <div class="col-md-4 text-end">
                <div class="dropdown">
                  <button
                    class="option-btn btn px-1 pb-1"
                    type="button"
                    id="playlistActions"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="bi bi-three-dots-vertical"></i>
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <button class="dropdown-item" @click="openTrackModal">
                        Upload Track
                      </button>
                    </li>
                    <li>
                      <button class="dropdown-item" @click="openPlaylistModal">
                        Edit Playlist
                      </button>
                    </li>
                    <li>
                      <button
                        class="dropdown-item"
                        data-bs-toggle="modal"
                        :data-bs-target="'#playlistDeleteModal' + playlist.id"
                      >
                        Delete Playlist
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="col-md-6 col-sm-12 col-lg-4"
          v-for="track in playlist.tracks"
          :key="track.id"
        >
          <playlist-track :track="track"></playlist-track>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCloudinaryPId } from "@/utils/misc"
import { Modal } from "bootstrap"
import PlaylistDeleteModal from "@/components/dashboard/playlist/PlaylistDeleteModal.vue"
import PlaylistTrack from "@/components/dashboard/playlist/PlaylistTrack.vue"
import PlaylistModal from "@/components/dashboard/playlist/PlaylistModal.vue"
import TrackModal from "@/components/dashboard/playlist/TrackModal.vue"
import moment from "moment"

import {
  AdvancedImage,
  lazyload,
  responsive,
  placeholder
} from "@cloudinary/vue"

export default {
  name: "AdminPlaylist",
  props: { playlist: Object, index: Number },
  components: {
    PlaylistDeleteModal,
    TrackModal,
    PlaylistTrack,
    PlaylistModal,
    AdvancedImage
  },
  data() {
    const editedTrack = {
      title: "",
      artist_names: "",
      song: null,
      playlist_id: this.playlist.id
    }
    const imgPlugins = [
      lazyload(),
      responsive(),
      placeholder({ mode: "pixelate" })
    ]
    return {
      trackMethod: "create",
      playlistMethod: "edit",
      editedTrack,
      defaultTrack: Object.freeze(Object.assign({}, editedTrack)),
      imgPlugins
    }
  },
  computed: {
    playlistImg() {
      const img = this.cld.image(getCloudinaryPId(this.playlist.image_path))
      return img
    }
  },
  methods: {
    openTrackModal() {
      const modal = new Modal(this.$refs.trackModal.$refs.trackModal)
      modal.show()
    },
    closeTrackModal() {
      this.editedTrack = Object.assign({}, this.defaultTrack)
    },
    openPlaylistModal() {
      const modal = new Modal(this.$refs.playlistModal.$refs.playlistModal)
      modal.show()
    },
    getCloudinaryPId: getCloudinaryPId,
    moment: moment
  }
}
</script>
