<template>
  <div class="accordion mt-2" id="playlistAccordion">
    <div v-for="(playlist, index) in playlists" :key="playlist.id">
      <admin-playlist :playlist="playlist" :index="index"></admin-playlist>
    </div>
  </div>
  <button
    type="button"
    class="btn btn-outline-primary my-4"
    @click="loadNextPage"
    v-if="page.last_page > page.current_page"
  >
    See more playlists
    <i class="bi bi-arrow-up-right-square-fill"></i>
  </button>
</template>

<script>
import { mapState, mapActions } from "vuex"
import AdminPlaylist from "@/components/dashboard/playlist/AdminPlaylist.vue"
export default {
  name: "Playlists",
  components: { AdminPlaylist },
  async mounted() {
    await this.getPlaylists({ page: 1, section: "admin", search: "" })
  },
  computed: {
    ...mapState("playlist", ["playlists", "page"])
  },
  methods: {
    ...mapActions("playlist", ["getPlaylists"]),
    loadNextPage() {
      this.getPlaylists({ page: this.page.current_page + 1, search: "" })
    }
  }
}
</script>
